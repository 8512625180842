import { Button, Error500, Paragraph } from '@hexa-ui/components';
import { DocumentTitle } from 'admin-portal-shared-services';
import { FormattedMessage, useIntl } from 'react-intl';
import { HideAppHeader } from '../../styles/GlobalStyles';

import { ErrorFloatingContainer, PageErrorContainer } from './PageError.styles';

interface Props {
  error?: {
    message: string;
    buttonMessage?: string;
  };
  onRetry?: () => void;
}

const PageError = ({ error, onRetry }: Props): JSX.Element => {
  const { formatMessage } = useIntl();
  console.log({ error });

  return (
    <>
      <DocumentTitle>{formatMessage({ id: 'title.pageError' })}</DocumentTitle>
      <PageErrorContainer role="alert" data-testid="fallback-error-page">
        <Error500 headerText={formatMessage({ id: 'message.error.default' })} />
        <ErrorFloatingContainer>
          {error && <Paragraph alignment="center">{error.message}</Paragraph>}
          {onRetry && (
            <Button type="button" onClick={onRetry} data-testid="fallback-error-button">
              {error.buttonMessage?.length ? (
                <FormattedMessage id={error.buttonMessage} />
              ) : (
                formatMessage({ id: 'button.tryAgain' })
              )}
            </Button>
          )}
        </ErrorFloatingContainer>
      </PageErrorContainer>
      <HideAppHeader />
    </>
  );
};

export default PageError;
