import { LoadingBuzz } from '@hexa-ui/components';
import { HideAppHeader } from '../../styles/GlobalStyles';

import { PageLoadingContainer } from './PageLoading.styles';

const PageLoading = (): JSX.Element => {
  return (
    <>
      <PageLoadingContainer>
        <LoadingBuzz dataTestId="loading-buz-component" size="xlarge" />
      </PageLoadingContainer>
      <HideAppHeader />
    </>
  );
};

export default PageLoading;
