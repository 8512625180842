import { useHasPermission, useUserMetadata } from 'admin-portal-shared-services';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PATH_BASE } from '../../consts/routes';
import { Country } from '../../models/country';
import { Scopes } from '../../models/scopes';
import PageError from '../../pages/PageError/PageError';
import PageLoading from '../../pages/PageLoading/PageLoading';

export interface EnvConfig {
  name: string;
  ENV: string;
  ENV_TYPE: string;
  OPTIMIZELY_KEY: string | undefined;
  SEGMENT_KEY: Record<Country, string> | undefined;
}

export const defaultInitialEnvConfig: EnvConfig = {
  name: '',
  ENV: '',
  ENV_TYPE: '',
  OPTIMIZELY_KEY: '',
  SEGMENT_KEY: {
    [Country.BR]: '',
    [Country.US]: '',
    [Country.AR]: '',
    [Country.CO]: '',
    [Country.DO]: '',
    [Country.EC]: '',
    [Country.MX]: '',
    [Country.PA]: '',
    [Country.PE]: '',
    [Country.ZA]: '',
    [Country.HN]: '',
    [Country.SV]: '',
  },
};

export const EnvContext = React.createContext(defaultInitialEnvConfig);

export const useEnvContext = () => React.useContext(EnvContext);

export const EnvProvider = ({
  env,
  children,
}: {
  env: EnvConfig;
  children: React.ReactNode;
}): JSX.Element => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { data: metadata, isLoading: isLoadingMetadata } = useUserMetadata();
  const hasOperationsReadTaskPermission = useHasPermission([Scopes.BeesForceOperationsGlobalRead]);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentVendor = searchParams.get('vendorId');
  const [userHasCurrentVendor, setUserHasCurrentVendor] = useState(true);

  useEffect(() => {
    if (!currentVendor) {
      setUserHasCurrentVendor(true);
      return;
    }
    const vendorIndex = metadata?.vendors.findIndex((vendor) => vendor.id === currentVendor);
    setUserHasCurrentVendor(vendorIndex >= 0);
  }, [searchParams, metadata]);

  const handleSearchRedirect = () => {
    navigate(PATH_BASE);
  };

  if (isLoadingMetadata) {
    return <PageLoading />;
  } else if (!hasOperationsReadTaskPermission || !userHasCurrentVendor) {
    return (
      <PageError
        error={{
          message: formatMessage({ id: 'message.error.default' }),
          buttonMessage: 'button.newSearch',
        }}
        onRetry={!userHasCurrentVendor ? handleSearchRedirect : undefined}
      />
    );
  } else if (metadata?.supportedCountries === undefined || metadata?.vendors == undefined) {
    return (
      <PageError
        error={{
          message: formatMessage({ id: 'message.error.noVendor' }),
        }}
      />
    );
  }

  return <EnvContext.Provider value={env}>{children}</EnvContext.Provider>;
};
