import { createGlobalStyle } from 'styled-components';
import { BREAKPOINT_MOBILE } from '../../../consts/screen';
import { StyledContainer } from '../../../providers/AppThemeProvider/AppThemeProvider.styles';

export const SidebarStyles = createGlobalStyle`
    ${StyledContainer} {
        padding-left: 6.5rem;

        @media (max-width: ${BREAKPOINT_MOBILE}) {
            padding-left: 1.5rem !important;
        }
    }
`;
