import { LoadingDots } from '@hexa-ui/components';
import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

const SearchResults = lazy(() => import('./pages/SearchResults/SearchResults'));
const OperationsPanel = lazy(() => import('./pages/OperationsPanel/index'));
const VisitDetails = lazy(() => import('./pages/VisitDetails/VisitDetails'));

const Router = (): JSX.Element => {
  return (
    <Routes>
      <Route
        path={'/force/operations-panel'}
        element={
          <Suspense fallback={<></>}>
            <OperationsPanel />
          </Suspense>
        }
      />
      <Route
        path={'/force/operations-panel/search-results'}
        element={
          <Suspense fallback={<></>}>
            <SearchResults />
          </Suspense>
        }
      />
      <Route
        path={'/force/operations-panel/search-results/visit-details'}
        element={
          <Suspense fallback={<></>}>
            <VisitDetails />
          </Suspense>
        }
      />
    </Routes>
  );
};
export default Router;
