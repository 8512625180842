export enum Scopes {
  BeesForceOperationsGlobalRead = 'BeesForceAdmin.OperationsGlobal.Read',

  BeesForceAdminSimpleTaskTemplateRead = 'BeesForceAdmin.SimpleTaskTemplate.Read',
  BeesForceAdminSimpleTaskTemplateWrite = 'BeesForceAdmin.SimpleTaskTemplate.Write',
  BeesForceAdminSimpleTaskSubmissionRead = 'BeesForceAdmin.SimpleTaskSubmission.Read',
  BeesForceAdminSimpleTaskSubmissionWrite = 'BeesForceAdmin.SimpleTaskSubmission.Write',
  BeesForceAdminSurveyTaskTemplateRead = 'BeesForceAdmin.SurveyTaskTemplate.Read',
  BeesForceAdminSurveyTaskTemplateWrite = 'BeesForceAdmin.SurveyTaskTemplate.Write',
  BeesForceAdminSurveyTaskSubmissionWrite = 'BeesForceAdmin.SurveyTaskSubmission.Write',
  BeesForceAdminSurveyTaskSubmissionRead = 'BeesForceAdmin.SurveyTaskSubmission.Read',
  BeesForceAdminPriorizatorWrite = 'BeesForceAdmin.Priorizator.Write',
  BeesForceAdminPriorizatorRead = 'BeesForceAdmin.Priorizator.Read',
  BeesForceAdminUserManagementWrite = 'BeesForceAdmin.UserManagement.Write',
  BeesForceAdminUserManagementRead = 'BeesForceAdmin.UserManagement.Read',
  BeesForceAdminPhotoTaskTemplateWrite = 'BeesForceAdmin.PhotoTaskTemplate.Write',
  BeesForceAdminPhotoTaskTemplateRead = 'BeesForceAdmin.PhotoTaskTemplate.Read',
  BeesForceAdminPhotoTaskSubmissionWrite = 'BeesForceAdmin.PhotoTaskSubmission.Write',
  BeesForceAdminPhotoTaskSubmissionRead = 'BeesForceAdmin.PhotoTaskSubmission.Read',
  BeesForceAdminPhotoPriorizatorWrite = 'BeesForceAdmin.PhotoPriorizator.Write',
  BeesForceAdminPhotoPriorizatorRead = 'BeesForceAdmin.PhotoPriorizator.Read',
  BeesForceAdminVOneCardRead = 'BeesForceAdmin.VOneCard.Read',
  BeesForceAdminVTwoCardRead = 'BeesForceAdmin.VTwoCard.Read',
  BeesForceAdminTaskGroupsWrite = 'BeesForceAdmin.TaskGroups.Write',
  BeesForceAdminTaskGroupsRead = 'BeesForceAdmin.TaskGroups.Read',
}

export const taskManagementV1Scopes = [Scopes.BeesForceAdminVOneCardRead];

export const taskManagementV2Scopes = [Scopes.BeesForceAdminVTwoCardRead];

export const operationPanelScopes = [Scopes.BeesForceOperationsGlobalRead];

export const sidebarTaskGroupsScopes = [
  Scopes.BeesForceAdminTaskGroupsWrite,
  Scopes.BeesForceAdminTaskGroupsRead,
];
