import { ThemeProvider, ThemeProviderType } from '@hexa-ui/theme';
import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import { useHubTheme } from 'admin-portal-shared-services';
import { useMemo, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Sidebar from './components/Molecules/Sidebar/Sidebar';
import { FORCE_HUB_THEME } from './consts/theme';
import { IntlProvider } from './i18n';
import { EnvProvider } from './providers/EnvProvider';
import { EnvConfig } from './providers/EnvProvider/EnvProvider';
import QueryProvider from './providers/QueryProvider';
import Router from './Router';

export default function App(props: EnvConfig) {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'bees-hub-force-operations-panel-mfe',
    seed: 'bees-hub-force-operations-panel-mfe',
  });

  useMemo(() => sessionStorage.setItem('session_id', uuidv4()), []);
  const [hubTheme] = useState<ThemeProviderType['theme']>(FORCE_HUB_THEME);
  useHubTheme(hubTheme);

  return (
    <QueryProvider>
      <IntlProvider>
        <BrowserRouter>
          <EnvProvider env={props}>
            <StylesProvider generateClassName={generateClassName}>
              <ThemeProvider theme={hubTheme}>
                <Sidebar />
                <Router />
              </ThemeProvider>
            </StylesProvider>
          </EnvProvider>
        </BrowserRouter>
      </IntlProvider>
    </QueryProvider>
  );
}
