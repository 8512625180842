import { CategorySearch, Checklist1, List2 } from '@hexa-ui/icons';
import { useHasPermission, useSidebar } from 'admin-portal-shared-services';
import { useIntl } from 'react-intl';
import {
  PATH_BASE,
  PATH_TASK_GROUPS,
  PATH_TASK_MANAGEMENT_V1,
  PATH_TASK_MANAGEMENT_V2,
} from '../../../consts/routes';
import {
  operationPanelScopes,
  sidebarTaskGroupsScopes,
  taskManagementV1Scopes,
  taskManagementV2Scopes,
} from '../../../models/scopes';
import { SidebarStyles } from './Sidebar.styles';

export const SidebarIcons = {
  TaskManagement: (): React.JSX.Element => <Checklist1 />,
  OperationsPanel: (): React.JSX.Element => <CategorySearch />,
  TaskGroups: (): JSX.Element => <List2 />,
};

const Sidebar = (): React.JSX.Element => {
  const { formatMessage } = useIntl();
  const sidebarItems = [];

  if (useHasPermission(taskManagementV1Scopes, 'OR')) {
    sidebarItems.push({
      id: 'tasks-v1',
      title: formatMessage({ id: 'sidebar.taskManagement' }),
      icon: SidebarIcons.TaskManagement,
      path: PATH_TASK_MANAGEMENT_V1,
    });
  }

  if (useHasPermission(taskManagementV2Scopes, 'OR')) {
    sidebarItems.push({
      id: 'tasks-v2',
      title: formatMessage({ id: 'sidebar.taskManagement' }),
      icon: SidebarIcons.TaskManagement,
      path: PATH_TASK_MANAGEMENT_V2,
    });
  }

  if (useHasPermission(operationPanelScopes, 'OR')) {
    sidebarItems.push({
      id: 'operation',
      title: formatMessage({ id: 'sidebar.operationPanel' }),
      icon: SidebarIcons.OperationsPanel,
      path: PATH_BASE,
    });
  }

  if (useHasPermission(sidebarTaskGroupsScopes, 'OR')) {
    sidebarItems.push({
      id: 'task-groups',
      title: formatMessage({ id: 'sidebar.taskGroups' }),
      icon: SidebarIcons.TaskGroups,
      path: PATH_TASK_GROUPS,
    });
  }

  useSidebar({
    items: sidebarItems,
    utils: [],
  });

  return <SidebarStyles hasAppHeader={false} hasBreadcrumb={false} />;
};

export default Sidebar;
